@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);

$desktop: 1000px;

body {
    background: url('../images/photos/fond.jpg') no-repeat center;
    background-size: cover;
    font-family: 'Open sans', sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100%;
}
