.footer-main {
    background-color: #7ec07e;
    color: black;
    font-weight: bold;
    display: flex;
    flex-direction: row;

    .footer-case {
        width: 33%;
        font-size: 1.25rem;
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .footer-link {
            text-decoration: none;
            font-size: 1.5rem;
            margin: 0.5rem 0;
            color: black;
        }
        .footer-link:hover {
            color: brown;
        }
    }

    .footer-case--icons {
        width: 33%;
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 33%);
        column-gap: 1%;
        
        .footer-icon {
            justify-self: center;
            align-self: center;
            width: 100%;
            max-width: 100px;
        }
        .footer-icon--mini {
            justify-self: center;
            align-self: center;
            width: 100%;
            max-width: 75px;
        }
    }
}