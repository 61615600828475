.header-main {
    width: 100%;
    display: flex;
    flex-direction: row;

    .header-logos {
        width: 75%;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: nowrap;

        .container-logo {
            width: 20%;
            max-width: 180px;

            .logo {
                width: 100%;
            }
        }
    }
    
    .header-flag {
        width: 25%;
        display: flex;
        justify-content: flex-end;
        padding: 1rem;

        .container-flag {
            width: 50%;
            max-width: 70px;

            .flag {
                border: 2px solid transparent;
                border-radius: 5px;
                width: 100%;
            }
            .flag:hover {
                border: 2px solid cyan;
                cursor: pointer;
            }
        }
    }
}

.header-back-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem 1rem;

    .left {
        .link {
            color: white;
            padding: 1rem;
            text-decoration: none;
            font-weight: bold;
            font-size: 1.5rem;
            text-transform: uppercase;
            border-radius: 10px;
            border: 1px solid white;
            background-color: forestgreen;
        }
        .link:hover {
            cursor: pointer;
            background-color: white;
            border: 1px solid forestgreen;
            color: forestgreen;
            //box-shadow: -1px 1px 5px 5px silver;
        }
    }

    .right {
        width: 100%;
        font-size: 2.5rem;
        font-weight: bold;
        text-align: center;
    }
}

.blur {
    opacity: 0.5;
}
